import Vuex from 'vuex'
import Vue from 'vue'

Vue.use(Vuex);

const actions = {
    scroll_top(content, scroll_top) {
        content.state.scrollTop = scroll_top
    },

    get_md_navigation(content, h_navigation) {
        content.state.navigation = h_navigation
    },

    blog_left_two_is_hidden(content, is_hidden) {
        content.state.blogLeftTwoIsHidden = is_hidden
    }
}

const mutations= {

}

const state = {
    scrollTop: null,

    navigation: [],

    blogLeftTwoIsHidden: false
}

const getters = {

}

export default new Vuex.Store({
    actions,
    mutations,
    state,
    getters
})