import Vue from 'vue'
import App from './App.vue'
import 'font-awesome/css/font-awesome.css'
import mavonEditor from 'mavon-editor'
import VueRouter from 'vue-router'
import router from './router'
import store from './store'
import animated from 'animate.css'
import echarts from 'echarts'
import china from 'echarts/map/json/china.json'

echarts.registerMap('china', china)

Vue.config.productionTip = false

Vue.prototype.$travels = window.travels
Vue.prototype.$blogs = window.blogs.sort((a, b) => b['data'] - a['data'])
Vue.prototype.$movie = window.movie
Vue.prototype.$echarts = echarts

Vue.use(mavonEditor);
Vue.use(VueRouter);
Vue.use(animated);

Vue.prototype.$bus = new Vue()

const VueRouterPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
