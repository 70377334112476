<template>
    <div class="travels">
        <Maptravels />
        <div class="travels-years">
            <div class="travels-year" v-for="(year, index) in travelsYears" :key="index">
                <div class="year-box">
                    <div class="line-left"></div>
                    <div class="year">{{year}}</div>
                    <div class="line-right"></div>
                </div>
                <div class="year-travels-list">
                    <div class="year-travels" v-for="(travelsSingle, index) in yearTravels[year]" :key="index">
                        <div class="first-line">
                            <p class="year-date">{{travelsSingle.date.slice(4, 6) + '-' + travelsSingle.date.slice(6, 8)}}</p>
                            <p class="title">{{travelsSingle.title}}</p>
                        </div>
                        <div class="cities">
                            <p class="city" v-for="(city, index) in travelsSingle.cities" :key="index">
                                {{city}}
                            </p>
                        </div>
                        <div class="days">
                            <div class="day" v-for="(day, index) in travelsSingle.days" :key="index">
                                <div class="day-place" v-for="(place, index) in day" :key="index">
                                    {{place + '\xa0\xa0\xa0'}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Maptravels from './Maptravels.vue'

export default {
    name: 'Travel',
    components: {
        Maptravels
    },
    data() {
        return {
            travels: [],
            travelsYears: [],
            yearTravels: {},
            mapCities: []
        }
    },
    mounted() {
        const travels_have_date = this.$travels.filter(t => {
            if(t.date) {
                return t
            }
        })
        this.travels = travels_have_date.sort((a, b) => b['date'] - a['date'])
        this.travelsYears = [...new Set(this.travels.map(m => m.date.slice(0, 4)))]
        this.travels.reverse()
        this.travelsYears.forEach(y => {
            let tempList = this.travels.filter(b => {
                if(b.date.slice(0, 4) === y) return b
            })
            this.yearTravels[y] = tempList
        })
    }
}
</script>

<style lang="less" scoped>
.travels {
    margin: 50px 0 50px;
    min-height: 900px;
    min-width: 300px;

    .travels-years {
        margin: 100px 0 200px;

        .travels-year {
            margin: 0 0 50px;

            .year-box {
                display: flex;
                position: relative;
                margin: 0 50px 30px;

                .year {
                    width: 80px;
                    // flex-shrink: 0;
                    font-size: 17px;
                    text-align: center;
                }

                .line-left,
                .line-right {
                    flex: 1;
                    border-top: 1px solid rgba(34, 34, 34, 0.05);
                    position: relative;
                    top: 20px;
                }
            }

            .year-travels-list {
                display: flex;
                flex-wrap: wrap;

                .year-travels {
                    margin: 0 0 70px 50px;
                    width: 315px;

                    .first-line {
                        display: flex;

                        .title {
                            margin-left: 30px;
                            font-weight: bold;
                        }
                    }

                    .cities {
                        display: flex;
                        flex-wrap: wrap;

                        .city {

                            &:not(:last-of-type) {
                                margin-right: 10px;
                            }
                        }
                    }

                    .days {
                        margin-top: 20px;
                        // color: #999;

                        .day {
                            display: flex;
                            flex-wrap: wrap;
                            border-left: 3px solid rgba(34, 34, 34, 0.1);
                            padding-left: 10px;
                            &:not(:first-of-type) {
                                margin-top: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>